
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Title {
  margin-bottom: spacing(2);
  color: $color-primary-teal;
}

.Description {
  margin-bottom: spacing(2);
}

.Helper {
  color: $color-primary-black;
  margin-bottom: spacing(2);
}

.Video {
  margin-bottom: spacing(2);
}

.Options {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: spacing(2);

  @include mq($breakpoint-tablet) {
    max-height: 150px;
  }
}

.RatingOptions {
  margin-bottom: spacing(5);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include mq($breakpoint-tablet) {
    justify-content: start;
  }
}

.Submit {
  display: block;
  margin: 0 auto;
  background-color: $color-primary-teal;
  color: $color-primary-white;
  width: 300px;
  transition: $duration-short $easing-standard;

  &:hover {
    background-color: $color-primary-black;
    transition: $duration-short $easing-standard;
  }

  @include mq($breakpoint-tablet) {
    margin: 0;
  }
}

.isDark {
  .Description,
  .Helper {
    color: $color-primary-white;
  }

  .Submit {
    background-color: $color-primary-white;
    color: $color-primary-teal;
    &:hover {
      background-color: $color-primary-teal;
      color: $color-primary-white;
    }
  }
}

.disabled {
  &.Submit {
    background-color: $color-primary-grey-lighter;
  }
}
