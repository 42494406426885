
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Downloads {
  padding: spacing(2) spacing(3);

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(4);
  }
}

.Title {
  padding-bottom: spacing(3);
}

.Description {
  padding-bottom: spacing(4);
  color: $color-primary-grey;
}

.FeaturedDownload {
  display: block;
  padding-bottom: spacing(2);

  @include mq($breakpoint-tabletLandscape) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    &_noImage {
      .FeaturedImageWrapper {
        display: none;
      }
    }
  }
}

.FeaturedImage {
  width: 100%;
  border: 1px solid $color-primary-grey;
}

.FeaturedImageWrapper {
  flex: 1.3 1;
  padding-bottom: spacing(2);
  padding-right: 0;
  @include mq($breakpoint-tabletLandscape) {
    padding-right: spacing(3);
  }

  @include mq($breakpoint-desktopMedium) {
    padding-right: spacing(4);
  }
}

.DownloadBody {
  flex: 2 1 0%;
}

.DownloadTitle {
  padding-bottom: spacing(2);
}

.DownloadDescription {
  padding-bottom: spacing(4);
}

.FileInfo {
  color: $color-primary-grey-light;
}

.DownloadLink {
  color: $color-primary-teal;
  transition: $duration-short $easing-standard;
  display: block;
  margin-bottom: spacing(2);

  &:hover {
    color: $color-primary-grey;
    transition: $duration-short $easing-standard;
  }
}

.DownloadsGrid {
  display: block;
  padding-top: spacing(4);
  border-top: 1px solid $color-primary-grey-light;

  @include mq($breakpoint-tabletLandscape - 100) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.DownloadsList {
  &_item {
    display: flex;
    justify-content: space-between;
    padding: spacing(2);
    border-radius: 4px;
    &Odd {
      background-color: $color-primary-grey-lightest;
    }
  }
  &_title {
    color: $color-primary-grey;
  }
  &_link {
    color: $color-primary-teal;
    font-weight: 600;
    transition: $duration-short $easing-standard;
  }
}

.RemainingDownloadsExpander {
  flex-direction: column;
  align-items: center;
  &_content {
    width: 100%;
    margin-top: spacing(2);
  }
}

.gridRight {
  .Wrapper {
    display: block;
    @include mq($breakpoint-tabletLandscape) {
      display: flex;
      flex-direction: column;
    }
  }

  .FeaturedDownload {
    display: block;
    flex: 1.5 1 0%;
    padding-right: 0;

    @include mq($breakpoint-tabletLandscape) {
      padding-right: spacing(4);
    }

    @include mq($breakpoint-desktop + 100) {
      flex: 3.2 1 0%;
      display: flex;
      flex-direction: row;
    }
  }

  .FeaturedImageWrapper {
    padding-bottom: spacing(2);
    padding-right: 0;

    @include mq($breakpoint-desktop + 100) {
      padding-right: spacing(3);
    }

    @include mq($breakpoint-desktopMedium) {
      padding-right: spacing(4);
    }
  }

  .DownloadsGrid {
    flex: 1.4 1;
    flex-wrap: wrap;
    flex-basis: 0%;
    border: none;
    padding: 0;
  }
}

.isDark {
  .Description,
  .DownloadTitle,
  .DownloadDescription,
  .FileInfo {
    color: $color-primary-white;
  }

  .DownloadLink {
    color: $color-primary-white;

    &:hover {
      color: $color-primary-teal;
    }
  }
}
