
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Carousel {
  display: block;
  position: relative;
}

.TitleWrapper {
  margin: spacing(3) 0;
  max-width: 300px;
  min-height: 30px;
  @include mq($breakpoint-tablet) {
    max-width: unset;
  }
}

.Flickity {
  overflow: hidden;
  &:focus {
    outline: none;
  }
}

.hideMobileControls {
  @include mq(0, $breakpoint-tablet - 1) {
    .Controls {
      display: none;
    }
  }
}

.isDark {
  .Title {
    color: $color-primary-white;
  }
}
