
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SocialShare {
  position: relative;
  padding: spacing(2) 0;

  @include mq($breakpoint-desktop) {
    padding: spacing(3) 0;
  }
}

.Title {
  padding-bottom: spacing(1);

  @include mq($breakpoint-tablet) {
    line-height: spacing(7);
  }

  @include mq($breakpoint-desktopMedium) {
    line-height: spacing(8);
  }

  @include mq($breakpoint-desktopLarge) {
    line-height: spacing(9);
  }
}

.Wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.SocialIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $color-primary-grey-lightest;
  margin-bottom: spacing(2);
  cursor: pointer;
  transition: $easing-standard $duration-short;

  &:not(:last-child) {
    margin-right: spacing(2);
  }

  &:hover {
    background-color: $color-primary-teal;
    transition: $easing-standard $duration-short;
  }

  @include mq($breakpoint-tabletLandscape) {
    width: 60px;
    height: 60px;
    &:not(:last-child) {
      margin-right: spacing(3);
    }
  }

  @include mq($breakpoint-desktopLarge) {
    width: 100px;
    height: 100px;
  }
}

.Icon {
  width: 15px !important;
  height: 15px !important;

  @include mq($breakpoint-tabletLandscape) {
    width: 25px !important;
    height: 25px !important;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 40px !important;
    height: 40px !important;
  }

  svg {
    vertical-align: middle;
  }

  svg path {
    fill: $color-primary-black;
  }
}

.isDark {
  .Title {
    color: $color-primary-white;
  }

  .Icon svg path {
    fill: $color-primary-white;
  }

  .SocialIcon {
    background-color: $color-primary-grey-mid;
    &:hover {
      background-color: $color-primary-teal;
    }
  }
}
