
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Answer {
  will-change: opacity;
}

.Feedback {
  padding-bottom: spacing(2);
}

.AnswerWrapper {
  display: block;
  justify-content: space-between;
  padding-bottom: spacing(2);
  @include mq($breakpoint-tablet) {
    display: flex;
  }
}

.Title {
  color: $color-primary-teal;
  padding-bottom: spacing(2);
}

.isDark {
  .Feedback,
  .Explanation {
    color: $color-primary-white;
  }
}
