
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FeaturedMedia {
  color: $color-primary-grey;
  padding: spacing(2) spacing(3);

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(4);
  }
}

.Content {
  @include mq($breakpoint-tablet) {
    width: 85%;
  }
}

.Title {
  margin-bottom: spacing(6);
}

.MediaImage {
  display: flex;
  flex: 0 1 37rem;
  justify-content: center;
  transition: $easing-standard 0.2s;

  &:hover {
    transform: scale(1.003);
    transition: $easing-standard 0.2s;
  }
}

.Theme {
  padding: 0 0 spacing(4);
  @include mq($breakpoint-desktopMedium) {
    padding: spacing(4) 0;
  }
}

.Summary {
  margin-top: spacing(1);
  margin-bottom: spacing(3);

  @include mq($breakpoint-desktopMedium) {
    margin-top: spacing(3.5);
  }
}

.ReleaseDate {
  margin-bottom: spacing(3);
}
