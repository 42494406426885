
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.NativeSelectControl {
  display: block;
  width: 100%;
  height: spacing(5);
  border: 1px solid $color-common-grey75;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
  border-radius: 0;

  @include mq($breakpoint-desktop) {
    height: spacing(6);
  }

  @include mq($breakpoint-desktopLarge) {
    height: spacing(7) + 3;
  }

  &:hover,
  &:focus {
    transition-duration: $duration-shortest;
  }

  &:hover {
    border-color: $color-common-grey50;
  }

  &:focus {
    outline: 0;
  }
}

.multiple {
  height: auto;
  resize: vertical;
  overflow-y: auto;
}

@each $key, $value in $color-status-list {
  .#{$key} {
    border-color: $value !important;
  }
}
