
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FilmRatingStrip {
  background-color: $color-primary-white;
  position: sticky;
  top: calc(100vh - 145px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: $radius-small;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
  padding: spacing(2) spacing(1);
  margin: 0 spacing(3) 0;
  z-index: 2;

  @include mq($breakpoint-tablet) {
    flex-direction: row;
    top: calc(100vh - 170px);
  }
}

.Title {
  font-weight: bold;
}

.LearnRatingsText {
  font-weight: bold;
  transition: $easing-standard $duration-short;
  @include mq($breakpoint-tablet) {
    max-width: 25%;
  }

  @include mq($breakpoint-desktop) {
    max-width: 15%;
  }

  &:hover {
    text-decoration: underline;
    transition: $easing-standard $duration-short;
  }
}

.RatingsWrapper {
  padding: spacing(0.5);
  @include mq($breakpoint-tablet) {
    padding: 0 spacing(2);
  }
  @include mq($breakpoint-desktopMedium) {
    padding: 0 spacing(4);
  }
}

.AgeRating {
  margin: 0 spacing(0.75);
  width: 33px !important;
  height: 29.3333px !important;

  @include mq($breakpoint-tabletLandscape) {
    width: 55px !important;
    height: 48.8889px !important;
  }

  @include mq($breakpoint-desktopMedium) {
    width: 90px !important;
    height: 80px !important;
    margin: 0 spacing(2);
  }

  @include mq($breakpoint-desktopLarge) {
    width: 108px !important;
    height: 96px !important;
  }
}
