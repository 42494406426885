
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.Fish {
  position: absolute;
  z-index: -1;
}

.small {
  width: 40px;
}

.medium {
  width: 80px;
}

.large {
  width: 120px;
}
