
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.DownloadItem {
  text-align: center;
  padding-bottom: spacing(4);
  display: block;

  @include mq($breakpoint-tabletLandscape - 100) {
    padding: 0 spacing(2) spacing(2);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &:hover {
    .DownloadItemText {
      color: $color-primary-grey;
    }
  }
}

.DownloadItemImage {
  align-self: center;
  border: 1px solid $color-primary-grey;
}

.DownloadItemTitle {
  padding-top: spacing(2);
  color: $color-primary-teal;
}

.DownloadItemFileInfo {
  color: $color-primary-grey-light;
}

.DownloadItemText {
  color: $color-primary-teal;
  transition: $duration-short $easing-standard;

  &:hover {
    color: $color-primary-grey;
    transition: $duration-short $easing-standard;
  }
}

.gridRight {
  &.DownloadItem {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 2fr 4fr;
    min-height: 0%;
    padding: 0 0 spacing(4) 0;

    @include mq($breakpoint-tabletLandscape) {
      margin: 0 0 0 spacing(2);
      padding: 0 0 spacing(2) 0;
    }
  }

  .DownloadItemImage {
    grid-column: 1;
    -ms-grid-column: 1;
  }

  .DownloadItemInfoWrapper {
    padding-left: spacing(2);
    grid-column: 2;
    -ms-grid-column: 2;
  }

  .DownloadItemTitle {
    padding: 0 0 spacing(4) 0;
  }

  &.isDark {
    .DownloadItemFileInfo,
    .DownloadItemTitle {
      color: $color-primary-white;
    }

    .DownloadItemText {
      color: $color-primary-white;

      &:hover {
        color: $color-primary-teal;
      }
    }
  }
}
