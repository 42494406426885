
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Option {
  display: flex;
  align-items: center;
  margin: 0 spacing(2) spacing(2) 0;
  min-width: 150px;
}

.Title {
  padding-left: spacing(1);
}

.RatingOption {
  background-color: $color-primary-grey-lightest;
  padding: spacing(2);
  display: inline-block;
  border-radius: $radius-small;
  cursor: pointer;
  transition: $duration-short $easing-standard;
  margin: spacing(1);

  @include mq($breakpoint-tablet) {
    margin: 0 spacing(2) spacing(2) 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    transition: $duration-short $easing-standard;
    background-color: $color-primary-grey-lighter;
  }

  &.checked {
    background-color: $color-primary-grey-lighter;
  }
}

.Rating {
  position: relative;
  text-align: center;
  height: 47px !important;
  width: 53px !important;

  @include mq($breakpoint-desktopMedium) {
    height: 56px !important;
    width: 62.5px !important;
  }

  @include mq($breakpoint-desktopLarge) {
    height: 88px !important;
    width: 99px !important;
  }

  svg {
    height: 100% !important;
    width: auto !important;
  }
}

.isDark {
  &.Option {
    color: $color-primary-white;
  }

  &.RatingOption {
    background-color: unset;
    &.checked {
      background-color: $color-primary-white;
    }
    &:hover {
      background-color: $color-primary-white;
    }
  }
}
