
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../../Primitive/Type/Type.module.scss';

.FaqWrapper {
  padding: spacing(2) spacing(3);

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(4);
  }
}

.FaqTitle {
  margin-bottom: spacing(4);
}

.FaqContent {
  font-family: $font-body;
  font-weight: normal;
  overflow: hidden;
  min-height: 80px;
  font-size: spacing(3);
}

.isDark {
  .Content,
  div {
    color: $color-primary-white;
  }
}
