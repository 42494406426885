
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';
.SplitPanelWrapper {
  position: relative;
}

.Line {
  position: absolute;
  bottom: 99%;
  z-index: 0;

  @include mq(0, $breakpoint-tablet - 1) {
    display: none;
  }
}

.Squiggle {
  position: absolute;
  top: -180px;
  left: 15%;
  z-index: -1;
  transform: translate(-50%, 0);

  @include mq($breakpoint-tablet) {
    top: -260px;
  }

  @include mq($breakpoint-desktop, $breakpoint-desktopMedium - 1) {
    top: -180px;
  }

  @include mq($breakpoint-desktopLarge) {
    top: -360px;
  }
}

.SquiggleIcon {
  padding-left: 35px;

  @include mq($breakpoint-tablet) {
    width: 100px !important;
    padding-left: 45px;
  }

  @include mq($breakpoint-desktop, $breakpoint-desktopMedium - 1) {
    width: 70px !important;
    padding-left: 33px;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 135px !important;
    padding-left: 63px;
  }
}
