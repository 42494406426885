
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.MediaListHeader {
  display: block;
  text-align: left;
  padding-bottom: spacing(2);
  &:hover {
    .Title {
      transition: $duration-short $easing-standard;
      color: $color-primary-teal;
    }
  }
}

.Image {
  margin-bottom: spacing(2);
}

.Title {
  margin-bottom: spacing(2);
  color: $color-primary-black;
  transition: $duration-short $easing-standard;
}

.isDark {
  .Title,
  .Description {
    color: $color-primary-white;
  }
}
