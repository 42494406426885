
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Questions {
  padding-bottom: spacing(4);
}

.Question {
  border: none;
  padding-bottom: spacing(4);
  color: $color-primary-grey;
}

.Option {
  color: $color-primary-black;
}

.Description {
  color: $color-primary-grey;
  padding-bottom: spacing(3);
}

.QuestionText {
  padding-bottom: spacing(2);
}

.Button {
  padding: spacing(2) spacing(12);
}

.isDark {
  .Description,
  .Question,
  .Option,
  .QuestionText {
    color: $color-primary-white;
  }
}

.Title {
  color: $color-primary-teal;
}
