
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Form {
  &,
  > * + * {
    padding-bottom: spacing(2);
  }

  font-size: spacing(2.25); // 18
  line-height: spacing(3); // 24

  @include mq($breakpoint-desktop) {
    font-size: spacing(3); // 24
    line-height: spacing(4); // 32
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(3.75); // 30
    line-height: spacing(5); // 40
  }
}

.VideoContainer {
  max-width: 600px;
  margin: 1em auto
}

.Title {
  color: $color-primary-teal;
  padding-bottom: spacing(2);
}

.SubmitButton {
  margin-top: spacing(2);
}

.SubmissionError {
  margin-bottom: 16px;
}

.isDark {
  & > p,
  label,
  div {
    color: $color-primary-white;
  }

  textarea,
  input {
    color: $color-primary-grey;
  }
}
