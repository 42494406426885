
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AnswerBox {
  flex: 1 1 48%;
  display: block;
  padding: spacing(3);
  margin-bottom: spacing(2);
  border: 2px solid black;
  border-radius: $radius-small;

  @include mq($breakpoint-tablet) {
    &:first-child {
      margin-right: spacing(3);
    }
  }
}

.Rating {
  margin-top: spacing(2);
}

.IssueWrapper {
  padding: spacing(4) 0 0;
}

.List {
  list-style-position: inside;
  font-weight: bold;
  padding-top: spacing(0.5);
}

.isDark {
  &.AnswerBox {
    color: $color-primary-white;
  }
}
