
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.GameWidget {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 1000;
  scrollbar-width: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;

  @include mq($breakpoint-tablet) {
    padding: spacing(2);
  }
}
.ContentContainer {
  z-index: 1;
  padding-left: 8px;
  padding-right: 8px;
  height: 100%;

  @include mq($breakpoint-tablet) {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.FamilyIcon {
  position: absolute;
  bottom: 0;
  right: 20%;
  z-index: 0;
}
.FishOne {
  position: absolute;
  top: 50%;
  right: 10%;
  z-index: 0;
}
.FishTwo {
  position: absolute;
  bottom: 20%;
  left: 10%;
  z-index: 0;
}
.GameWidgetContainer {
  background-color: $color-highlight-yellow;
  height: 100%;
  margin: auto;
  padding: spacing(6) spacing(1);
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  @include mq($breakpoint-tablet) {
    padding: 5px spacing(2);
    border-radius: spacing(4);
  }
  @include mq($breakpoint-desktopLarge) {
    padding: 5px spacing(4);
  }
}
.CloseButton {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  width: 40px;
  min-width: 40px;
  position: absolute;
  right: spacing(4);
  top: spacing(3);
  z-index: 1001;
  svg {
    width: 40px;
  }
  @include mq($breakpoint-desktop) {
    width: 50px;
    min-width: 50px;
    right: 50px;
    svg {
      width: 50px;
    }
  }
}
.GameWidgetIntro {
  position: relative;
  width: 100%;
  margin-bottom: spacing(4);
}
.GameWidgetTitle {
  width: 100%;
  text-align: center;
  padding-top: spacing(2);
  display: flex;
  flex-direction: column;
  row-gap: spacing(0.5);
  @include mq($breakpoint-tablet) {
    padding-top: 0;
    row-gap: spacing(1);
  }
}

.IssueList {
  display: flex;
  row-gap: spacing(1);
  flex-direction: column;
  @include mq($breakpoint-desktop) {
    display: grid;
    column-gap: spacing(3);
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    row-gap: spacing(2);
  }
}
.IssueItem {
  cursor: pointer;
  width: 100%;
  margin-bottom: spacing(2);
  transition: all ease-in-out 0.3s;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  &:hover {
    filter: brightness(1.15);
  }
}
.IssueItemLabelWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: spacing(1);
  background-color: $color-highlight-green;
  padding: spacing(2);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  &.IssueItemLabelWrapActive {
    box-shadow: inset 0 0 0 spacing(0.5) white;
  }
}
.IssueItemLabel {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: 1.2;
  column-gap: spacing(2);
  @include mq($breakpoint-desktop) {
    font-size: 24px;
  }
}
.IssueItemLabelType {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.IssueItemLabelEmoji {
  font-size: 30px;
  @include mq($breakpoint-desktop) {
    font-size: 44px;
  }
}
.IssueItemModal {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background: white;
  min-width: 80%;
  border-radius: spacing(2);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 6px solid $color-highlight-yellow;
  z-index: 1;
  @include mq($breakpoint-desktop) {
    min-width: 800px;
  }
}
.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
}
.IssueItemModalClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: spacing(1);
  cursor: pointer;
}
.IssueItemModalContent {
  padding: spacing(4);
  display: flex;
  flex-direction: column;
  row-gap: spacing(2);
}
.IssueItemModalHeading {
  text-align: center;
  color: $color-highlight-yellow;
}

.AgeForm {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin: spacing(6) auto;
}

.Input {
  padding: spacing(2) spacing(4);
  border-radius: spacing(0.5);
  text-align: center;
  font-size: 20px;
  border: unset;
  margin-bottom: spacing(2);
}
.InputError {
  margin-bottom: spacing(1);
  text-align: center;
}
.Button {
  padding: spacing(2) spacing(4);
  background-color: $color-highlight-green;
  border-radius: 100px;
  font-size: 32px;
  color: white;
  font-family: $font-heading;
}
