
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.ArticleHeader {
  position: relative;
  color: $color-primary-white;
  overflow-x: hidden;
  margin-top: spacing(4);
  margin-bottom: spacing(-4);

  &.hasImage {
    margin-top: spacing(-4);
    margin-bottom: 0;

    @include mq($breakpoint-tablet) {
      margin-top: spacing(-6);
    }

    @include mq($breakpoint-desktop) {
      margin-top: spacing(-7);
    }

    @include mq($breakpoint-desktopMedium) {
      margin-top: spacing(-8);
    }

    @include mq($breakpoint-desktopLarge) {
      margin-top: spacing(-9);
    }
  }
}

.Image {
  max-width: unset;
  max-height: 650px;

  @include mq($breakpoint-tabletLandscape) {
    height: 100%;
    width: 100%;
    max-height: unset;
  }
}

.Strip {
  position: relative;
  background-color: unset;

  .padded & {
    padding-top: spacing(2);
    @include mq($breakpoint-desktop) {
      padding-top: spacing(3);
    }
  }
}

.Information {
  color: $color-primary-black;
}

.TitleWrapper {
  display: flex;
  align-items: center;
  padding: spacing(1) 0;
  color: $color-primary-black;
}

.AgeRating {
  margin-right: spacing(2);
  width: 44px !important;
  height: 44px !important;

  @include mq($breakpoint-tablet) {
    width: 55px !important;
    height: 55px !important;
  }

  @include mq($breakpoint-desktop) {
    width: 72px !important;
    height: 72px !important;
  }

  @include mq($breakpoint-desktopMedium) {
    width: 94px !important;
    height: 94px !important;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 117px !important;
    height: 117px !important;
  }
}

.hasImage {
  .Strip {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: spacing(1) 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .Information,
  .TitleWrapper {
    color: $color-primary-white;
  }
}

.isDark {
  .Strip {
    background-color: rgba(55, 74, 82, 0.75);
  }

  .BreadCrumb {
    color: $color-primary-white;
  }

  .Information,
  .TitleWrapper {
    color: $color-primary-white;
  }
}
