
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../config';

.HorizontalLine {
  position: absolute;
  height: 0;
  width: $gap-mobile - $margin-mobile;
  top: 30px;
  left: -$gap-mobile + $margin-mobile;
  border: 2px solid $color-primary-grey-lighter;

  @media print {
    top: 60px;
  }

  &::after {
    content: '';
    position: absolute;
    right: -2px;
    height: 20px;
    top: -10px;
    border: 2px solid $color-primary-grey-lighter;
  }

  &::before {
    content: '';
    position: absolute;
    width: spacing(3);
    height: spacing(3);
    top: spacing(-1.5);
    left: spacing(-1.5);
    background-color: $color-primary-grey-lighter;
    border-radius: 50%;
    z-index: 1;
  }
}

.SubGroup {
  padding: spacing(2);
  position: relative;
  margin-left: $gap-mobile;
}

@media print {
  .SubGroup {
    page-break-inside: avoid;
  }

  .SubGroupTitle {
    margin-top: 30px;
  }
}

.SubGroupTitle {
  padding-bottom: spacing(3);
  @include mq($breakpoint-tabletLandscape - 150) {
    padding-bottom: spacing(5);
  }
}

.Milestone {
  padding-bottom: spacing(2);
  @include mq($breakpoint-desktop) {
    padding-bottom: spacing(4);
  }
}

.BodyWrapper {
  margin-bottom: spacing(2);
  @include mq($breakpoint-tabletLandscape - 150) {
    display: inline-block;
    vertical-align: top;

    .FloatingImage + & {
      width: calc(100% - 230px);
    }
  }

  @media print {
    display: inline-block;
    vertical-align: top;

    .FloatingImage + & {
      width: calc(100% - 230px);
    }
  }
}

.FloatingImage {
  display: inline-block;
  padding-bottom: spacing(1);
  img {
    max-width: unset;
    @include mq($breakpoint-tabletLandscape - 150) {
      max-width: 200px;
    }
  }
  @media print {
    display: inline-block;
    img {
      max-width: 200px;
    }
  }
}

.FullWidthImage,
.Video,
.Audio {
  margin-bottom: spacing(2);
}

.CallToAction {
  display: block;
  color: $color-primary-teal;
  margin-top: spacing(2);

  &:hover {
    color: $color-primary-grey;
  }
}

@include mq($breakpoint-desktop) {
  .HorizontalLine {
    top: 31px;
    width: $gap-tablet - $margin-tablet;
    left: -$gap-tablet + $margin-tablet;
  }

  .SubGroup {
    margin-left: $gap-tablet;
  }
}

@include mq($breakpoint-desktopMedium) {
  .HorizontalLine {
    top: 35px;
    width: $gap-desktop - $margin-desktop;
    left: -$gap-desktop + $margin-desktop;
  }

  .SubGroup {
    margin-left: $gap-desktop;
  }
}

@include mq($breakpoint-desktopLarge) {
  .HorizontalLine {
    top: 40px;
    width: $gap-desktopLg - $margin-desktopLg;
    left: -$gap-desktopLg + $margin-desktopLg;
  }

  .SubGroup {
    margin-left: $gap-desktopLg;
  }
}

.isDark {
  .CallToAction {
    &:hover {
      color: $color-primary-white;
    }
  }
}

// Image modifiers

.float-left {
  margin-right: 0;
  @include mq($breakpoint-tabletLandscape - 150) {
    margin-right: spacing(2);

    & + .BodyWrapper {
      width: calc(100% - 220px);
    }
  }

  @media print {
    margin-right: spacing(2);
  }
}

.float-right {
  margin-left: 0;
  @include mq($breakpoint-tabletLandscape - 150) {
    margin-left: spacing(2);
  }
}
