
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PageWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 spacing(2);
}

.Page {
  display: block;
  padding: 0 spacing(1);
  &:hover {
    color: $color-primary-teal;
  }
}

.SelectedPage {
  display: block;
  padding: 0 spacing(1);
  outline: 0;
  color: $color-primary-teal;
}

.Icon {
  &:hover svg path {
    fill: $color-primary-teal;
  }
}

.isDark {
  .Page {
    color: $color-primary-white;
    &:hover {
      color: $color-primary-teal;
    }
  }
  .Icon {
    svg path {
      fill: $color-primary-white;
    }

    &:hover svg path {
      fill: $color-primary-teal;
    }
  }
}
