
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.ColumnWrapper {
  position: relative;
}

.Line {
  position: absolute;
  top: -120px;
  z-index: -1;

  @include mq(0, $breakpoint-tablet - 1) {
    display: none;
  }
}
