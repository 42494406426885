
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ListItem {
  padding: spacing(2);
  transition: $duration-short $easing-standard;

  &:nth-child(even) {
    background-color: $color-primary-grey-white;
  }

  &:nth-child(odd) {
    background-color: $color-primary-grey-lightest;
  }

  &:first-child {
    border-radius: $radius-small $radius-small 0 0;
  }

  &:last-child {
    border-radius: 0 0 $radius-small $radius-small;
  }

  &:only-child {
    border-radius: $radius-small;
  }

  @include mq($breakpoint-desktopMedium) {
    padding: spacing(4) spacing(3);
  }

  &:hover {
    transition: $duration-short $easing-standard;
    color: $color-primary-teal;

    .DownloadIcon svg path {
      transition: $duration-short $easing-standard;
      fill: $color-primary-teal;
    }
  }
}

.isDark {
  background-color: $color-primary-grey-mid;
  color: $color-primary-white;
  &.ListItem {
    &:nth-child(even) {
      background-color: $color-primary-grey-mid-light;
    }

    &:nth-child(odd) {
      background-color: $color-primary-grey-mid;
    }
  }

  .DownloadIcon svg path {
    fill: $color-primary-white;
  }
}

.Category {
  font-weight: 600;
  margin-right: 5px;
}

.Text {
  display: flex;
  flex-direction: row;
}
