
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FilmInformation {
  padding: spacing(2) 0;

  @include mq($breakpoint-desktop) {
    padding: spacing(3) 0;
  }
}
