
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Title {
  margin-bottom: spacing(1);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(2.5);
  }
}
