
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.ArticleHeader {
  color: $color-primary-black;
}

.Title {
  margin-top: spacing(2);
}

.isDark {
  &.ArticleHeader {
    color: $color-primary-white;
  }

  .BreadCrumb {
    color: $color-primary-white;
    &:hover {
      color: $color-primary-teal;
    }
  }
}
