
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FilmInformationList {
  padding: spacing(2) 0;

  @include mq($breakpoint-desktop) {
    padding: spacing(3) 0;
  }
}

.Title {
  padding-bottom: spacing(1);
  @include mq($breakpoint-tablet) {
    line-height: spacing(7) !important;
  }

  @include mq($breakpoint-desktopMedium) {
    line-height: spacing(8) !important;
  }

  @include mq($breakpoint-desktopLarge) {
    line-height: spacing(9) !important;
  }
}
