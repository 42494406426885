
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Poll {
  padding-bottom: spacing(2);
}

.Title {
  padding-bottom: spacing(2);
  color: $color-primary-teal;
}
