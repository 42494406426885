
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Results {
  padding-bottom: spacing(4);
  color: $color-primary-grey;
}

.Description {
  padding-bottom: spacing(2);
}

.Result {
  padding-bottom: spacing(4);
}

.QuestionText {
  padding-bottom: spacing(2);
}

.Wrapper {
  padding-bottom: spacing(1);
  color: $color-primary-black;
}

.Option {
  padding-bottom: spacing(0.5);
  max-width: 85%;
}

.ProgressBarWrapper {
  position: relative;
}

.Percentage {
  position: absolute;
  right: 0;
  bottom: spacing(3.4);
  color: $color-primary-teal;
}

.isDark {
  .Description,
  .QuestionText,
  .Option {
    color: $color-primary-white;
  }
}

.Title {
  color: $color-primary-teal;
}
