
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.progressBarContainer {
  position: absolute;
  top: 20px;
  right: 0;
  height: 100%;
}

.progressBar {
  z-index: 10;
  position: sticky;
  top: 20px;
  right: 0;
  height: 600px;
  width: 10px;
  overflow: hidden;
  border-radius: 10px;
  background: $color-common-grey95;
}

.progressBarBar {
  background: $color-primary-teal;
  width: 100%;
}
