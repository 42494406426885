
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ScoreStep {
  background-color: black;
  color: white;
  max-width: 1000px;
  margin: spacing(2) auto 0;
  position: relative;
  @include mq($breakpoint-tablet) {
    border-radius: spacing(2);
  }
}
.ScoreStepHeading {
  padding: spacing(2) spacing(1);
  text-align: center;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ScoreStepShareButton {
  background-color: $color-highlight-green;
  padding: spacing(1) spacing(2);
  margin-left: spacing(2);
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
}
.ScoreDetailsContainer {
  padding: spacing(4) spacing(2);
  max-width: 740px;
  margin: 0 auto;
}
.ScoreDetailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;
  flex-direction: column;
  @include mq($breakpoint-desktop) {
    flex-direction: row;
  }
}

.ScoreDetailsHeading {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  margin-top: spacing(2);
  line-height: 1.4;
  max-width: 500px;
  @include mq($breakpoint-desktop) {
    text-align: right;
  }
}
.ScoreDetails {
  padding: spacing(8) 0;
  display: flex;
  flex-direction: column;
  row-gap: spacing(8);
  align-items: center;
  justify-content: space-between;
  @include mq($breakpoint-desktop) {
    flex-direction: row;
    column-gap: spacing(4);
  }
}
.ScoreDetailsContent {
  max-width: 450px;
}
.AgeRating {
  width: 150px !important;
  height: auto !important;
}

.ScoreDetails .Point {
  color: $color-highlight-yellow;
  font-family: $font-heading;
}
.PointDetails {
  margin-top: spacing(0.5);
  padding-top: spacing(0.5);
  border-top: 1px solid $color-highlight-yellow;
}
.ScoreDetailsFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .Player {
    opacity: 0.5;
    padding-left: spacing(1);
  }
  & .PlayerName {
    font-family: $font-hand-writing;
    font-size: 30px;
    font-style: italic;
  }
}
.ScoreStepOthers {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: spacing(2) auto;
  padding: spacing(4);
  background: white;
  border-radius: spacing(2);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.BehindClassification {
  margin-bottom: spacing(2);
}
.BehindClassificationDescription,
.BehindClassificationDescription p {
  color: $color-primary-grey;
}
.BehindClassificationDescription {
  &ratingU {
    h1,
    h2,
    h3 {
      color: $color-highlight-green;
    }
  }
  &ratingPG {
    h1,
    h2,
    h3 {
      color: $color-highlight-yellow;
    }
  }
  &rating12 {
    h1,
    h2,
    h3 {
      color: $color-highlight-orange;
    }
  }
  &rating12A {
    h1,
    h2,
    h3 {
      color: $color-highlight-orange;
    }
  }
}
.ChevronDownIcon {
  display: flex;
  height: auto !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50px;

  svg {
    fill: white !important;
    path {
      fill: white;
    }
  }
}
.LeaderboardContainer {
  max-width: 1000px;
  margin: spacing(2) auto 0;
  display: flex;
  justify-content: center;
}
.LeaderboardModal {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -10%);
  background: white;
  width: 90%;
  border-radius: spacing(2);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 6px solid $color-highlight-yellow;

  @include mq($breakpoint-desktop) {
    max-width: 800px;
  }
}
.LeaderboardModalClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: spacing(1);
  cursor: pointer;
}
.LeaderboardModalContent {
  padding: spacing(2);
  display: flex;
  flex-direction: column;
  row-gap: spacing(2);
  @include mq($breakpoint-desktop) {
    padding: spacing(4);
  }
}
.LeaderboardModalHeading {
  text-align: center;
  color: $color-highlight-yellow;
}
.Button {
  padding: spacing(2) spacing(4);
  background-color: $color-highlight-green;
  border-radius: 100px;
  font-size: 32px;
  color: white;
  font-family: $font-heading;
}
.Leaderboard {
  margin-top: spacing(2);
  padding: spacing(2);
  background-color: $color-primary-teal;
  border-radius: 16px;
}
.LeaderboardHeader {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: spacing(1);
  align-items: center;
  padding-bottom: spacing(1);
  .LeaderboardHeader1,
  .LeaderboardHeader2 {
    display: flex;
    flex-direction: column;
    row-gap: spacing(1);
    align-items: center;
  }
  @include mq($breakpoint-tablet) {
    flex-direction: row-reverse;
    justify-content: space-between;
    .LeaderboardHeader1 {
      row-gap: 0;
      align-items: flex-end;
    }
    .LeaderboardHeader2 {
      flex-direction: row;
      column-gap: spacing(1);
      font-size: 32px;
    }
  }
}
.LeaderboardTableHeading {
  display: grid;
  padding: spacing(1) 0 spacing(1) spacing(1);
  grid-template-columns: 44px auto 80px;
  font-weight: bold;
  .HeadingPoint {
    text-align: right;
  }
  @include mq($breakpoint-tablet) {
    grid-template-columns: 52px auto 80px;
  }
}
.LeaderboardTableBody {
  max-height: 420px;
  overflow: auto;
}
.LeaderboardTableBodyRow {
  display: grid;
  padding: spacing(1) 0 spacing(1) spacing(1);
  grid-template-columns: 44px auto 120px;
  align-items: center;
  .BodyPOS {
    width: 36px;
    height: 36px;
    justify-content: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    background-color: white;
    color: $color-primary-teal;
    span {
      padding-top: spacing(2);
    }
    @include mq($breakpoint-tablet) {
      font-size: 16px;
      width: 44px;
      height: 44px;
    }
  }
  .BodyName {
    text-transform: capitalize;
    @include mq($breakpoint-tablet) {
      font-size: 24px;
    }
  }
  .BodyPoint {
    text-align: right;
    font-size: 22px;
    @include mq($breakpoint-tablet) {
      font-size: 36px;
    }
  }
  @include mq($breakpoint-tablet) {
    padding-right: 12px;
    grid-template-columns: 52px auto 200px;
  }
  &:nth-child(2n) {
    background: rgba(0, 0, 0, 0.1);
  }
}
.LeaderboardPlayerScore {
  color: white;
  text-align: center;
  border-top: 5px solid white;
  .Tilde {
    padding: spacing(0.5) 0;
    margin: spacing(1) auto;
  }
  .LeaderboardTableBodyRow {
    text-align: left;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: spacing(2);
  }
  .BodyNameWrap {
    display: flex;
    align-items: center;
    column-gap: spacing(2);
    padding-left: spacing(2);
  }
}
.LeaderboardActionButtons {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
