
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Mailchimp {
  padding: spacing(2) spacing(3);

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(4);
  }
}
