
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Notification {
  padding: rem(15);
  margin-bottom: spacing(2);

  .Text {
    color: $color-common-white;
  }
}

.Headline {
  font-size: 28px;
  margin-bottom: rem(15);
}

.Subtitle {
  font-size: 20px;
}

.Error {
  background: $color-highlight-red;
}

.Success {
  background: $color-highlight-jade;
}
