
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.WatchStep {
  background-color: $color-highlight-green;
  max-width: 1000px;
  margin: spacing(2) auto 0;
  position: relative;
  @include mq($breakpoint-tablet) {
    border-radius: spacing(2);
  }
  &.correct-background {
    background-color: $color-highlight-green;
  }
  &.incorrect-background {
    background-color: $color-highlight-orange;
  }
}
.WatchStepTopContent {
  position: relative;
  text-align: center;
  padding: spacing(2.5) spacing(2);
  min-height: 64px;

  @include mq($breakpoint-desktop) {
    padding:spacing(1) spacing(2.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.WatchStepHeading {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}
.WatchStepTrailer {
  padding-top: 56.25%;
  position: relative;
}
.WatchStepTrailerPlayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.WatchStepContent {
  padding: spacing(2) spacing(2);
  position: relative;
  z-index: 10;
}
.IssueActionButtonContainer {
  position: relative;
  flex: 0 0 30%;
}
.IssueActionButtons {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: spacing(2);
  row-gap: spacing(2);
  @include mq($breakpoint-desktop) {
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-evenly;
  }
}

.IssueActionButton {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: spacing(2) spacing(2);
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: spacing(2);
  position: relative;
  z-index: 10;
  font-size: 18px;
  @include mq($breakpoint-desktop) {
    font-size: 20px;
    border-radius: 40px;
    padding: spacing(1) spacing(4);
  }
  @include mq($breakpoint-desktopLarge) {
    font-size: 20px;
  }
  &.IssueActionActiveButton {
    background-color: rgba(255, 255, 255, 0.66);

    & .IssueActionButtonText {
      color: black;
    }
  }
}
.IssueActionButtonText {
  color: white;
  font-family: $font-heading;
  max-height: 72px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
}
.ConfettiWrapper {
  position: absolute;
  width: 100px;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  height: 0;
  transition: height 2s opacity 2s ease-in-out;
  &.Show {
    opacity: 1;
    height: 150px;
  }
}
.ConfettiItem {
  position: absolute;
  @for $i from 0 through 150 {
    $w: random(8);
    $l: random(100);
    &.Confetti-#{$i} {
      bottom: -10%;
      left: unquote($l + '%');
      opacity: random() + 0.5;
      animation: drop-#{$i}
        unquote(2 + random() + 's')
        unquote(random() + 's')
        infinite;
      & span {
        font-size: 44px;
      }
    }

    @keyframes drop-#{$i} {
      100% {
        bottom: 110%;
        left: unquote($l + random(15) + '%');
      }
    }
  }
}

.IssueLabelIcon {
  font-size: 30px;
  padding: spacing(1) 0;

  @include mq($breakpoint-desktop) {
    font-size: 35px;
  }
}

.CountDownWrap {
  display: inline-block;
  padding: spacing(0.5) spacing(1);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: spacing(0.5);
  margin-top: spacing(2);
  @include mq($breakpoint-desktop) {
    position: absolute;
    right: spacing(2);
    top: spacing(1);
    margin-top: spacing(0);
  }
  & .Heading {
    font-size: 12px;
    line-height: 1;
    margin-bottom: spacing(0.5);
  }
  & .Time {
    font-size: 20px;
    line-height: 1;
  }
}

.ReactIcon {
  position: absolute;
  z-index: 10;
  top: 250px;
  right: 20px;
  transition: all;
  opacity: 0;
  &.ShowIcon {
    opacity: 1;
  }
  @include mq($breakpoint-desktop) {
    top: 350px;
    right: 50px;
  }
}
.ReactIconText {
  font-size: 100px;
  position: absolute;
  z-index: 10;
  top: -50%;
  left: -50%;
  transform: translate(-50%, -50%);
  @include mq($breakpoint-desktop) {
    font-size: 200px;
  }
}
.ReactIconConfetti {
  position: absolute;
  z-index: 9;
  top: -50%;
  left: -50%;
  transform: translate(-50%, -50%);
  height: 500px;
}

.Loading {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
    @include mq($breakpoint-desktop) {
      width: 200px;
      height: 200px;
    }
  }
}