
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.CustomCheckControl {
  display: inline-block;
  position: relative;
}

.CustomCheckControlControl {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.CustomCheckControlIndicator {
  position: relative;
  display: inline-block;
  width: spacing(3);
  height: spacing(3);
  background: $color-common-white;
  vertical-align: middle;
  border: 2px solid $color-primary-grey-dark;
  border-radius: $radius-tiny;
  transition: all $duration-standard $easing-standard;

  .CustomCheckControlControl:hover:not([disabled]) ~ &,
  .CustomCheckControlControl:focus ~ & {
    border-color: $color-primary-teal;
    transition-duration: $duration-shortest;
  }

  .CustomCheckControlControl:checked ~ & {
    border-color: $color-primary-teal;
  }

  .CustomCheckControlControl:hover:not([disabled]):checked ~ &,
  .CustomCheckControlControl:checked:focus ~ & {
    border-color: $color-primary-teal;
    box-shadow: 0 0 0 1px $color-primary-teal inset;
  }

  .CustomCheckControlControl:disabled ~ & {
    opacity: 0.6;
    border-color: $color-common-grey75;
    cursor: not-allowed;
  }

  &::after {
    position: absolute;
    content: '';
    opacity: 0;
    transform: scale(0);
    transition: opacity $duration-long $easing-standard,
      transform $duration-long cubic-bezier(0.6, 1.5, 0.8, 1.15);
  }

  .CustomCheckControlControl:checked ~ &::after {
    opacity: 1;
    transform: scale(1);
    transition-duration: $duration-shortest;
  }
}

//
// CHECKBOX
//

.checkbox {
  .CustomCheckControlIndicator::after {
    top: 50%;
    left: 50%;
    margin-left: -2.7px;
    margin-top: -7px;
    width: 5px;
    height: 12px;
    transform: scale(0) rotate(45deg);
    border-style: solid;
    border-color: $color-primary-teal;
    border-width: 0 3px 3px 0;
  }

  .CustomCheckControlControl:checked ~ .CustomCheckControlIndicator::after {
    transform: scale(1) rotate(45deg);
  }
  .CustomCheckControlControl:disabled ~ .CustomCheckControlIndicator::after {
    border-color: $color-common-grey75;
  }
}

//
// RADIO
//

.radio {
  .CustomCheckControlIndicator {
    border-radius: 50%;
  }
  .CustomCheckControlIndicator::after {
    top: 50%;
    left: 50%;
    width: spacing(2);
    height: spacing(2);
    margin-left: spacing(-1);
    margin-top: spacing(-1);
    border-radius: 50%;
    background-color: $color-primary-teal;
  }
  .CustomCheckControlControl:disabled .CustomCheckControlIndicator::after {
    background-color: $color-common-grey75;
  }
}

@each $key, $value in $color-status-list {
  .#{$key} {
    .CustomCheckControlIndicator {
      border-color: $value;
    }
    .CustomCheckControlControl:hover:not([disabled])
      ~ .CustomCheckControlIndicator,
    .CustomCheckControlControl:focus ~ .CustomCheckControlIndicator {
      border-color: $value;
    }
    .CustomCheckControlControl:checked ~ .CustomCheckControlIndicator {
      border-color: $value;
    }
    .CustomCheckControlControl:hover:not([disabled]):checked
      ~ .CustomCheckControlIndicator,
    .CustomCheckControlControl:checked:focus ~ .CustomCheckControlIndicator {
      border-color: $value;
      box-shadow: 0 0 0 1px $value inset;
    }
  }
  .checkbox.#{$key} {
    .CustomCheckControlIndicator::after {
      border-color: $value;
    }
  }
  .radio.#{$key} {
    .CustomCheckControlIndicator::after {
      background-color: $value;
    }
  }
}
