$color-common-black: hsl(0, 0%, 0%);
$color-common-grey25: hsl(0, 0%, 25%);
$color-common-grey50: hsl(0, 0%, 50%);
$color-common-grey75: hsl(0, 0%, 75%);
$color-common-grey95: hsl(0, 0%, 95%);
$color-common-white: hsl(0, 0%, 100%);

$color-primary-base: #1565c0;
$color-primary-light: #1976d2;
$color-primary-dark: #0d47a1;

$color-primary-black: #000;
$color-primary-teal: #00ada7;
$color-primary-grey: #374a52;
$color-primary-grey-dark: #262626;
$color-primary-grey-mid: #4f5f66;
$color-primary-grey-mid-light: #5f6e74;
$color-primary-grey-light: #879297;
$color-primary-grey-lighter: #c3c8cb;
$color-primary-grey-lightest: #f5f6f6;
$color-primary-grey-white: #f9fafa;
$color-primary-white: #fff;

$color-highlight-green: #0ac700;
$color-highlight-yellow: #fbad00;
$color-highlight-orange: #ff7d13;
$color-highlight-pink: #fb4f93;
$color-highlight-red: #dc0a0a;
$color-highlight-blue: #006ed2;
$color-highlight-jade: #050;

$color-rating-list: (
  'u': $color-highlight-green,
  'pg': $color-highlight-yellow,
  '12': $color-highlight-orange,
  '12a': $color-highlight-orange,
  '15': $color-highlight-pink,
  '18': $color-highlight-red
);

$color-status-success: #33691e;
$color-status-notice: #01579b;
$color-status-warning: #ff6f00;
$color-status-error: #b71c1c;

$color-status-list: (
  success: $color-status-success,
  notice: $color-status-notice,
  warning: $color-status-warning,
  error: $color-status-error
);
