
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .CaptionedMediaBody {}

.CaptionedMediaCaption {
  padding: spacing(1) 0;
  color: $color-primary-grey;
}

.isDark {
  .CaptionedMediaCaption {
    color: $color-primary-white;
  }
}
