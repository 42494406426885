
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.UploadControl {
  display: block;
  width: 100%;
  height: spacing(5);
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  background-color: $color-primary-white;
  transition: all $duration-standard $easing-standard;
  padding: spacing(1.5) - 1 spacing(2) - 1;
  background-clip: padding-box;
  border: 1px solid $color-primary-grey-lighter;
  border-radius: $radius-small;
  height: spacing(6) + 5;

  @include mq($breakpoint-desktop) {
    height: spacing(6) + 13;
  }

  @include mq($breakpoint-desktopLarge) {
    height: spacing(7) + 13;
  }

  &::placeholder {
    font-weight: bold;
  }

  &:hover,
  &:focus {
    transition-duration: $duration-shortest;
  }

  &:focus {
    box-shadow: 0 0 0 1px $color-primary-teal;
    outline: 0;
  }
}

// Status classes
@each $key, $value in $color-status-list {
  .#{$key} {
    border-color: $value !important;
  }
}
