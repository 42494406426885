
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.Shape {
  position: absolute;
  z-index: -1;
  left: -100px;
}

.small {
  width: 15%;
}

.medium {
  width: 20%;
}

.large {
  width: 30%;
}

.right {
  left: auto;
  right: -100px;
}
