
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Title {
  padding-bottom: spacing(4);
  color: $color-primary-teal;
}

.QuestionTitle {
  color: $color-primary-teal;
  padding-bottom: spacing(1);
}

.Question {
  padding-bottom: spacing(2);
  color: $color-common-black;
}

.Description {
  color: $color-common-black;
  padding-bottom: spacing(4);
}

.Score {
  color: $color-primary-teal;
  font-weight: bold;
}

.Results {
  color: $color-primary-teal;
  padding-bottom: spacing(3);
}

.Button {
  padding: spacing(2) spacing(12);
}

.ResultsText {
  display: flex;
}

.isDark {
  .Question,
  .Description,
  .ResultsText {
    color: $color-primary-white;
  }

  & > div {
    color: $color-primary-white;
  }
}

.SelectImage {
  img {
    outline: 2px solid transparent;
    outline-offset: -2px;
    transition: outline-color 300ms;
  }
  &:hover img,
  &:focus img {
    outline-color: rgba($color-primary-teal, 0.8);
    transition: outline-color 300ms;
  }
  &.Selected img {
    outline-color: $color-primary-teal;
  }
}
