
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ProgressBar {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: spacing(2);
  border-radius: $radius-small;
}

.ProgressBar::-webkit-progress-bar {
  border-radius: $radius-small;
  background-color: $color-primary-grey-lighter;
}

.ProgressBar::-webkit-progress-value {
  border-radius: $radius-small;
  background-color: $color-primary-teal;
}
