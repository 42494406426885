
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Contents {
  flex-direction: column !important;
}

.ContentsLinksContainer {
  padding: 1em 0;
  @include mq($breakpoint-desktop) {
    padding: 1em;
  }
}

.ContentsLinks {
  color: $color-primary-teal;
  display: block;
  transition: $duration-short $easing-standard;
  padding: 0.3em 0;
}

.Title {
  font-size: spacing(3);
}
.ToggleIcon {
  width: 24px !important;
}
.ToggleIcon path {
  fill: $color-primary-teal !important;
}
