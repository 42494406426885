
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.List {
  > li {
    margin-left: spacing(4);
  }
}

.inline {
  > li {
    display: inline-block;
    margin-left: 0;
  }
}

.unstyled {
  > li {
    list-style: none;
    margin-left: 0;
  }
}
