
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ContactDetails {
  padding: spacing(2) spacing(3);

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(4);
  }
}

.Title {
  padding-bottom: spacing(2);

  @include mq($breakpoint-desktopMedium) {
    padding-bottom: spacing(4);
  }
}

.Wrapper {
  padding: spacing(2) 0;
  border-top: 1px solid $color-primary-grey-light;
  border-bottom: 1px solid $color-primary-grey-light;

  @include mq($breakpoint-desktopMedium) {
    padding: spacing(4) 0;
  }
}

.DetailWrapper {
  padding-top: spacing(1);
}

.ContactDetail {
  display: inline;
  transition: $duration-short $easing-standard;

  &:hover {
    transition: $duration-short $easing-standard;
    color: $color-primary-teal;
  }

  &:not(:last-child)::after {
    content: ' | ';
    padding: 0 spacing(1);
    color: $color-primary-black;
  }
}

.isDark {
  .ContactDetail,
  .ContactDetail::after,
  .Wrapper {
    color: $color-primary-white;
    border-color: $color-primary-grey-mid;
  }

  .ContactDetail:hover {
    color: $color-primary-teal;
  }
}
