
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Title {
  padding-bottom: spacing(2);
}

.Text {
  color: $color-primary-black;
}

.Link {
  display: block;
  margin-top: spacing(3);
  color: $color-primary-teal;
  transition: $duration-short $easing-standard;

  &:hover {
    transition: $duration-short $easing-standard;
    color: $color-primary-grey;
  }
}

.Wrapper {
  background-color: $color-primary-grey-lightest;
  padding: spacing(2);
}

.isDark {
  .Wrapper {
    background-color: $color-primary-grey-mid-light;
  }

  .Text,
  .Link:hover {
    color: $color-primary-white;
  }
}
