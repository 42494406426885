
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .Hide {}

$HideWidths: (
  tablet: $breakpoint-tablet,
  desktop: $breakpoint-desktop,
  tabletlandscape: $breakpoint-tabletLandscape
);

@each $key, $value in $HideWidths {
  @include mq($value) {
    .at-#{$key} {
      display: none;
    }
  }

  @include mq($max: $value - 1) {
    .below-#{$key} {
      display: none;
    }
  }
}

// :export {
//   widths: $HideWidths;
// }
