
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Answer {
  color: $color-primary-black;
}

.AnswerText {
  font-style: italic;
  color: $color-primary-black;
}

.AnswerIntro {
  color: $color-primary-teal;
}

.AnswerBox {
  padding: spacing(2);
  background-color: $color-common-grey95;
  border: 1px solid $color-primary-teal;
  margin-bottom: spacing(2);
}
