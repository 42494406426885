
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'settings';
@import '../../Primitive/Type/Type.module.scss';

.Html {
  table {
    @extend .base;

    border-collapse: collapse;
    overflow-x: auto;
    width: 100%;
    table-layout: fixed;
    margin: 0 auto;
    scroll-snap-type: x proximity;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; // Hide scrollbar in IE and Edge
    border-radius: $radius-small;
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    @include mq(0, $breakpoint-tablet - 1) {
      display: block;
    }

    th {
      width: 10%;
      text-align: left;
      padding: spacing(1) spacing(1);
      background-color: $color-primary-grey-white;

      @include mq($breakpoint-desktop) {
        padding: spacing(2) spacing(1);
      }

      @include mq(1470px) {
        padding: spacing(2) spacing(1.5);
      }
    }

    tbody tr {
      &:nth-child(1) {
        border-top-left-radius: $radius-small;
        border-top-right-radius: $radius-small;
      }

      &:nth-child(even) {
        background-color: $color-primary-grey-white;
      }

      &:nth-child(odd) {
        background-color: $color-primary-grey-lightest;
      }
    }

    td {
      scroll-snap-align: start;
      width: 100%;
      padding: spacing(1) spacing(1);

      @include mq($breakpoint-desktop) {
        padding: spacing(2) spacing(1);
      }

      @include mq(1470px) {
        padding: spacing(2) spacing(1.5);
      }
    }
  }

  &.isDark {
    color: $color-common-white;

    th {
      background-color: $color-primary-grey-mid;
    }

    tbody tr {
      &:nth-child(even) {
        background-color: $color-primary-grey-mid;
      }

      &:nth-child(odd) {
        background-color: $color-primary-grey-mid-light;
      }
    }
  }
}
