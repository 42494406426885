
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Title {
  margin-bottom: spacing(3);
}

.RateATrailer {
  position: relative;
  overflow: hidden;
}

.isDark {
  .Title {
    color: $color-primary-white;
  }
}
