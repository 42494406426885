
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import './config';

.TimelineTitle {
  padding-bottom: spacing(2);
}

.TimelineItemWrapper {
  position: relative;
}

.TimelineItem {
  position: relative;
}

.VerticalLine {
  position: absolute;
  width: 4px;
  background-color: $color-primary-teal;
  bottom: 10px;
  left: $margin-mobile;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 20px;
    left: -8px;
    border: 2px solid $color-primary-teal;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 20px;
    left: -8px;
    border: 2px solid $color-primary-teal;
  }

  @include mq($breakpoint-desktop) {
    left: $margin-tablet;
  }

  @include mq($breakpoint-desktopMedium) {
    left: $margin-desktop;
  }

  @include mq($breakpoint-desktopLarge) {
    left: $margin-desktopLg;
  }
}
