
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.NavigationWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0.3rem;
  z-index: 10;
  min-height: 30px;

  &.bottomControls {
    bottom: 0;
    top: unset;
  }
}

.NavButton {
  height: 16px;
  width: 16px;
  cursor: pointer;

  &:first-child {
    margin-right: spacing(1);
  }

  &:last-child {
    margin-left: spacing(1);
  }

  @include mq($breakpoint-tabletLandscape) {
    height: 24px;
    width: 24px;
  }

  @include mq($breakpoint-desktopMedium) {
    width: 32px;
    height: 32px;
  }

  &:hover:not(.disabled) {
    svg path {
      fill: $color-primary-grey;
    }
  }
}

.disabled {
  cursor: default;
  svg path {
    fill: $color-primary-grey-light;
  }
}

.Icon {
  width: 16px !important;
  height: 16px !important;

  @include mq($breakpoint-tabletLandscape) {
    width: 24px !important;
    height: 24px !important;
  }

  @include mq($breakpoint-desktopMedium) {
    width: 32px !important;
    height: 32px !important;
  }
}

.isDark {
  &.NavigationWrapper {
    color: $color-primary-white;
  }

  .Icon:not(.disabled) svg path {
    fill: $color-primary-white;
  }
}
