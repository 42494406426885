
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.CheckControlGroup {
  > * + * {
    margin-top: spacing(1);
  }
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-top: 0;
    margin-right: spacing(2);
  }
}
